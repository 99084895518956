var atrpro_app = {};

// Define constants
atrpro_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
atrpro_app.DEFAULT_DURATION = 300;
atrpro_app.HEADER_HEIGHT = 140;

atrpro_app.toggleHeaderStyle = function() {

    function setSmallHeader() {
        jQuery('body').addClass('header--small');
        jQuery('#navbar-header').addClass('navbar__header--small');
        jQuery('#mobile-menu').addClass('mobile-menu--small');
    }

    function unsetSmallHeader() {
        jQuery('body').removeClass('header--small');
        jQuery('#navbar-header').removeClass('navbar__header--small');
        jQuery('#mobile-menu').removeClass('main-menu--small');
    }

    // On mobile device or if header is locked to small class
    if ((jQuery(window).width() < atrpro_app.BREAKPOINTS.LG ) || (jQuery('#navbar-header').hasClass("navbar__header--small--lock"))) {
        setSmallHeader();
        return;
    }

    if (jQuery(window).scrollTop() > atrpro_app.HEADER_HEIGHT) {
        setSmallHeader();
    } else {
        unsetSmallHeader();
    }
};

atrpro_app.toggleHomeHeroHeight = function() {
    if (window.matchMedia('(min-width: 992px)').matches) {
        $("#home-hero__intro").css('top', '0px');
        $("#our-business").css('padding-top', '0px');
    } else {
        var heroVideoHeight = $("#home-hero__video").height();

        var heroVideoHeaderHeight = heroVideoHeight + 80;
        $("#home-hero__intro").css('top', heroVideoHeaderHeight + 'px');

        var heroIntroHeight = $("#home-hero__intro").outerHeight();
        $("#our-business").css('padding-top', heroIntroHeight + 'px');
    }
};


atrpro_app.toggleAccordion = function(clickedItem) {
    var clickedItemCarousel = $(clickedItem).find('.accordion-carousel');

    if($(clickedItem).hasClass("accordion__item--active")) {
        $(clickedItem).removeClass("accordion__item--active");
        $(clickedItemCarousel).removeClass("accordion-carousel--active");

        atrpro_app.toggleCarouselWidth(clickedItemCarousel, 0);
        return;
    } else {
        $(".accordion__item").each(function( ) {
            var otherCarousel = $(this).find('.accordion-carousel');
            $(this).removeClass("accordion__item--active");
            $(otherCarousel).removeClass("accordion-carousel--active");

            atrpro_app.toggleCarouselWidth(otherCarousel, 0);
        });

        $(clickedItem).addClass("accordion__item--active");
        $(clickedItemCarousel).addClass("accordion-carousel--active");

        atrpro_app.toggleCarouselWidth(clickedItemCarousel, (clickedItemCarousel.parent().width() - 60));
        atrpro_app.toggleCarouselImage();

        setTimeout(function () {
            atrpro_app.toggleCarouselWidth(clickedItemCarousel, (clickedItemCarousel.parent().width() - 60));
        }, atrpro_app.DEFAULT_DURATION + 100);
    }
};

atrpro_app.toggleCarouselWidth = function(carousel, size) {
    carousel.css('width', size);

    carousel.trigger('refresh.owl.carousel');
};

atrpro_app.toggleCarouselImage = function(item) {
    var visibleCarousel = $(".accordion-carousel--active");

    var mediumImage = $(item).find("img").attr('data-image-medium');
    $(visibleCarousel).prev().find('img').attr("src", mediumImage);

    var largeImage = $(item).find("img").attr('data-image-large');
    $(visibleCarousel).prev().find('a').attr("href", largeImage);
};

atrpro_app.scrollTo = function(selector) {
    jQuery(selector).animatescroll( {
        padding: $("#navbar-header").height(),
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
};

atrpro_app.initLightcases = function() {
    // To uncomment if delete lihtcases on mobile
    //if (jQuery(window).width() > atrpro_app.BREAKPOINTS.SM) {
        $('a[data-rel^=lightcase]').lightcase({
            maxWidth: 1024,
            maxHeight: 768,
            onBeforeShow : {
                hackZIndex: function() {
                    // hack z-index lightcase component as modification in css does not seems to apply (see _custom.scss)
                    $('#lightcase-overlay').css('z-index', '2000');
                    $('#lightcase-loading').css('z-index', '2001');
                    $('#lightcase-case').css('z-index', '2002');
                    $('#lightcase-nav').css('z-index', '2003');
                    $('a[class^="lightcase-icon-"]').css('z-index', '2004');
                }
            },
        });
    //}
};

atrpro_app.setBlockHeight = function(parentSelector) {

    //Inner function
    function setBlockHeight(selector) {
        var maxHeight = 0;

        jQuery(selector).css('height', 'auto');

        jQuery(selector).each(function() {
            if (jQuery(this).height() > maxHeight) {
                maxHeight = jQuery(this).height();
            }
        });
        jQuery(selector).outerHeight(maxHeight);
    }

    // Xtra small devices
    setBlockHeight(parentSelector + ' .block-height-xs');

    if (jQuery(window).width() < atrpro_app.BREAKPOINTS.SM) {
        jQuery(parentSelector + ' .block-height-sm').css('height', 'auto');
        jQuery(parentSelector + ' .block-height-md').css('height', 'auto');
        jQuery(parentSelector + ' .block-height-lg').css('height', 'auto');
        return;
    }

    // Small devices
    setBlockHeight(parentSelector + '.block-height-sm');

    if (jQuery(window).width() < atrpro_app.BREAKPOINTS.MD) {
        jQuery(parentSelector + ' .block-height-md').css('height', 'auto');
        jQuery(parentSelector + ' .block-height-lg').css('height', 'auto');
        return;
    }

    // Medium devices
    setBlockHeight(parentSelector + ' .block-height-md');

    if (jQuery(window).width() < atrpro_app.BREAKPOINTS.LG) {
        jQuery(parentSelector + ' .block-height-lg').css('height', 'auto');
        return;
    }

    // Large devices
    setBlockHeight(parentSelector + ' .block-height-lg');
};

atrpro_app.initRecaptcha = function() {
    var developmentEnv = (location.hostname === 'localhost' || location.hostname === '127.0.0.1') ? '&env=development' : '';

    $.ajax({
        url: 'https://api1.pomlo.fr/recaptcha/register?apiKey=e00453c2-44f4-5e35-8e1f-1a22e98c3824' + developmentEnv,
        type: 'GET',
        success: function (data) {
            if (data && data.api && data.siteKey && data.options) {
                jQuery.getScript('//' + data.api.host + data.api.script + '?render=' + data.siteKey)
                    .done(function(script, textStatus) {
                        jQuery('body').append("<script>grecaptcha.ready(function(){grecaptcha.execute('" + data.siteKey + "', {action: 'homepage'}).then(" + data.options.callback + ");});</script>");
                    })
                    .fail(function( jqxhr, settings, exception ) {
                        console.log(exception);
                    });
            } else {
                console.log('Recaptcha data not valid.');
            }
        },
        error: function (data, l, error) {
            console.log(error);
        }
    });
};

// Vanilla JS function for recaptcha callback, name MUST BE recaptcha_cb
function recaptcha_cb(token) {
    //console.log('token', token);
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'g-recaptcha-response');
    input.setAttribute('id', 'g-recaptcha-response');
    input.setAttribute('value', token);
    document.getElementById('contact_form').appendChild(input);
}

jQuery(document).ready(function($) {
    atrpro_app.initRecaptcha();

    // Init interface
    atrpro_app.toggleHeaderStyle();

    $('#home-hero').imagesLoaded().done(function() {
        atrpro_app.toggleHomeHeroHeight();
    });

    $('#business-domains').imagesLoaded().done(function() {
        atrpro_app.setBlockHeight("#business-domains");
    });

    $('#our-team').imagesLoaded().done(function() {
        atrpro_app.setBlockHeight("#our-team");
    });

    // Accordion
    $(".accordion__item__toggler, .accordion__toggler").click(function() {
        var clickedItem = $(this).parents(".accordion__item");
        atrpro_app.toggleAccordion(clickedItem);
    });

    $('.item').click(function() {
        atrpro_app.toggleCarouselImage(this);
    });

    // Menu button
    $('#btn-menu-open').click(function() {

        $('#mobile-menu').addClass('mobile-menu--active');

        var scrollY = $(window).scrollTop();
        $('body').css({
            'position': 'fixed',
            'top': '-' + scrollY + 'px'
        });
    });

    $('#btn-menu-close').click(function() {
        $('#mobile-menu').removeClass('mobile-menu--active');

        var scrollY = $('body').css('top');
        $('body').css({
            'position': '',
            'top': ''
        });
        $(window).scrollTop(parseInt(scrollY || '0') * -1);
    });

    $("#contact_form").submit(function(e) {
        e.preventDefault();

        if(!$('#private-policy').is(':checked')){
            $("#private-policy-message").append("<i class=\"fas fa-exclamation\"></i>Envoi impossible, veuillez lire et accepter la politique de confidentialité");
            return false;
        }

        var mailMesssage = {
            name: $('#lastname').val() + ' ' + $('#firstname').val(),
            telephone: $('#telephone').val(),
            email: $('#email').val(),
            message: $('#message').val()
        },
        mailData = {
            apiKey: 'e00453c2-44f4-5e35-8e1f-1a22e98c3824',
            'g-recaptcha-response': $('#g-recaptcha-response').val(),
            env: (location.hostname === 'localhost' || location.hostname === '127.0.0.1') ? 'development' : '',
            to: 'contact@atr-pro.com',
            fields: mailMesssage
        };
        $.ajax({
            url: 'https://api1.pomlo.fr/email/send',
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(mailData),
            success: function (data) {
                $('#private-policy-message').html('Votre message vient d\'être envoyé avec succès, nous vous contacterons dans les plus brefs délais.');
            },
            error: function (data, l, error) {
                console.log(error);
                $('#private-policy-message').html('Votre message n\'a pas pu être envoyé, réessayez plus tard.');
            }
        });
    });

    //Carousel
    $('.accordion-carousel').imagesLoaded().done(function() {
        $('.accordion-carousel').owlCarousel({
            loop: false,
            autoplay: false,
            nav: true,
            dots: false,
            margin: 15,
            items: 3
        });
    });

    //Main Menu

    var scrollParameter = window.location.hash;
    if($(scrollParameter).length) {
        $('body').imagesLoaded().done(function() {
            atrpro_app.scrollTo(scrollParameter);
        });
    }

    if($('body').hasClass("page--scrollable")){
        $('.scroll-to').removeAttr("href");
    }

    $('.scroll-to').click(function() {
        if($(this).hasClass('scroll-to--mobile')) {
            $('#mobile-menu').removeClass('mobile-menu--active');

            $('body').css({
                'position': '',
                'top': ''
            });
        }

        var scrollLink = $(this).attr('data-link');
        atrpro_app.scrollTo(scrollLink);
    });

    atrpro_app.initLightcases();

});

jQuery(window).scroll(function() {
    // To debounce
    atrpro_app.toggleHeaderStyle();
});

jQuery(window).resize(function() {
    // To debounce
    atrpro_app.toggleHeaderStyle();
    atrpro_app.toggleHomeHeroHeight();
    atrpro_app.toggleCarouselWidth($(".accordion-carousel--active"),  ($(".accordion-carousel--active").parent().width() - 60));
    atrpro_app.setBlockHeight("#business-domains");
    atrpro_app.setBlockHeight("#our-team");

    // To uncomment if delete lihtcases on mobile
    //atrpro_app.initLightcases();

});
